<template>
  <v-navigation-drawer v-if="isAuth" v-model="drawer" app class="mainMenu">
    <v-toolbar flat class="transparent">
      <v-list class="pa-0">
        <v-list-item class="listMainMenu mb-2">
          <v-img class="avatar" src="./../../assets/images/Logo_new.png" max-width="205" contain />
        </v-list-item>
      </v-list>
    </v-toolbar>
    <v-divider />
    <v-list dense>
      <v-list-item to="/">
        <v-list-item-action>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-title>DASHBOARD</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-subheader>Task</v-subheader>
      <template v-for="(item, i) in items">
        <v-row v-if="item.heading" :key="i" align="center">
          <v-col cols="12" class="menu">
            <v-list-group v-if="item.heading" :prepend-icon="item.iconHeading" no-action>
              <v-list-item slot="activator">
                <v-list-item-title>{{ item.heading }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(menu, i) in item.menu"
                :key="i"
                :to="{ name: `${menu.name}` }"
                class="iconMenu listMenu_padding"
              >
                <v-list-item-action class="listMainMenu_Icon">
                  <v-icon v-text="menu.icon" />
                </v-list-item-action>
                <v-list-item-title v-text="menu.title" />
              </v-list-item>
            </v-list-group>
          </v-col>
        </v-row>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/extensions
// import conf from '@/helpers/config.js';

export default {
  data() {
    return {
      drawer: true,
      items: [
        {
          icon: 'dashboard',
          title: 'DASHBOARD'
        },
        { divider: true },
        {
          heading: 'JOB MANAGEMENT',
          iconHeading: 'mdi-calendar-text-outline',
          menu: [
            {
              icon: 'mdi-message-bulleted',
              title: 'Push Message',
              name: 'jobs'
            },
            {
              icon: 'mdi-message-processing',
              title: 'Automate Condition',
              name: 'automateCondition'
            },
            {
              icon: 'mdi-account-heart-outline',
              title: 'Job by new customer',
              name: 'welcomeJob'
            }
          ]
        },
        { divider: true },
        {
          heading: 'AUDIENCES',
          iconHeading: 'mdi-folder-account-outline',
          menu: [
            {
              icon: 'mdi-database-outline',
              title: 'Master Data',
              name: 'audienceMasterData'
            },
            {
              icon: 'mdi-book-account-outline',
              title: 'Custom Audience',
              name: 'createCustomAudience'
            }
          ]
        },
        { divider: true },
        {
          heading: 'CUSTOMERS',
          iconHeading: 'mdi-account-circle-outline',
          menu: [
            {
              icon: 'mdi-message-bulleted',
              title: 'Customer Registration',
              name: 'registrations'
            }
          ]
        },
        { divider: true },
        {
          heading: 'REPORTS',
          iconHeading: 'mdi-calendar-month-outline',
          menu: [
            {
              icon: 'mdi-calendar-range-outline',
              title: 'Contact By Messages',
              name: 'contactByMessagesMonthly'
            },

            {
              icon: 'mdi-message-processing-outline',
              title: 'Push Performances',
              name: 'performanceOffers'
            }
          ]
        },
        { divider: true },
        {
          heading: 'ADMINISTRATORS',
          iconHeading: 'mdi-application-settings',
          menu: [
            {
              icon: 'mdi-shield-account',
              title: 'User Managements',
              name: 'users'
            },
            {
              icon: 'mdi-account-group-outline',
              title: 'User Roles',
              name: 'roles'
            },
            {
              icon: 'mdi-cogs',
              title: 'Application Settings',
              name: 'applicationSetting'
            },
            {
              icon: 'mdi-view-dashboard-variant-outline',
              title: 'Agenda Dashboard',
              name: 'agendaDash'
            }
          ]
        },
        { divider: true },
        {
          heading: 'JOB MASTER DATA',
          iconHeading: 'mdi-database-settings-outline',
          menu: [
            {
              icon: 'mdi-music-circle-outline',
              title: 'Sound',
              name: 'masterDatasounds'
            },
            {
              icon: 'mdi-folder-text-outline',
              title: 'Category Profile',
              name: 'masterDataCategory'
            },
            {
              icon: 'mdi-tag-text-outline',
              title: 'Option Keys',
              name: 'masterDataOptionKeys'
            },
            {
              icon: 'mdi-comment-multiple-outline',
              title: 'Content Types',
              name: 'masterDataContentTypes'
            }
          ]
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      isAuth: 'auth/IS_AUTHENTICATED'
    })
  },

  methods: {
    gotoAgendaDash() {
      const token = localStorage.getItem('token');
      const urlDash = `/api/dash/${token}`;

      this.$router.replace(urlDash);
    }
  }
};
</script>

<style></style>
